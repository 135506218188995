import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { LogoFullColor, LogoIconColor } from '@/components/ui/Logo/Logo';
import { BREAKPOINTS, ROUTES } from '@/data';
import { Link } from '@/i18n/routing';
const NavLogo = () => {
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.MD}px)`
  });
  return <div className="mr-1 nav-logo" data-sentry-component="NavLogo" data-sentry-source-file="NavLogo.jsx">
      <Link href={ROUTES.HOME} data-sentry-element="Link" data-sentry-source-file="NavLogo.jsx">
        {isTabletOrMobile || isMobile ? <LogoIconColor className="w-10 h-10" /> : <LogoFullColor className="w-28" />}
      </Link>
    </div>;
};
export default NavLogo;