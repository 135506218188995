import NavMain from './NavMain';
import NavRight from './NavRight';
import { useTranslations } from '@/hooks';
const Nav = () => {
  const t = useTranslations();
  return <nav className="container flex items-center justify-between w-full h-full px-0" data-sentry-component="Nav" data-sentry-source-file="Nav.jsx">
      <NavMain data-sentry-element="NavMain" data-sentry-source-file="Nav.jsx" />
      <NavRight data-sentry-element="NavRight" data-sentry-source-file="Nav.jsx" />
    </nav>;
};
export default Nav;