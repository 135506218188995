import { useTranslations } from 'next-intl';
import EntertainmentDesignLogo from '@/components/ui/Logo/EntertainmentDesignLogo';
import { COMPANY_NAME, COMPANY_VAT_NUMBER, ROUTES, WHISTLEBLOWING_URL } from '@/data';
import { Link } from '@/i18n/routing';
const Copyright = () => {
  const t = useTranslations();
  return <div className="container px-0" data-sentry-component="Copyright" data-sentry-source-file="Copyright.jsx">
      <div className="md:h-16 copyright">
        <div>
          <span>
            {COMPANY_NAME} ® {new Date().getFullYear()} All Rights Reserved
          </span>

          <span>
            {t('payment.common.vat', {
            vat: 'generic'
          })} {COMPANY_VAT_NUMBER}
          </span>

          <Link href={ROUTES.TERMS} data-sentry-element="Link" data-sentry-source-file="Copyright.jsx">{t('navigation.terms')}</Link>

          <Link href={ROUTES.PRIVACY} data-sentry-element="Link" data-sentry-source-file="Copyright.jsx">{t('navigation.privacy')}</Link>

          <Link href={ROUTES.SLA} data-sentry-element="Link" data-sentry-source-file="Copyright.jsx">{t('navigation.sla')}</Link>

          <Link href={ROUTES.CONTRACT_OF_SERVICE} data-sentry-element="Link" data-sentry-source-file="Copyright.jsx">
            {t('navigation.contract_of_service')}
          </Link>

          <a href={WHISTLEBLOWING_URL} target="_blank" rel="noreferrer">
            Whistleblowing
          </a>
        </div>

        <div className="credits">
          <a className="flex items-center" href="//ed3sign.com?rel=matchfy" rel="noreferrer" target="_blank">
            <EntertainmentDesignLogo data-sentry-element="EntertainmentDesignLogo" data-sentry-source-file="Copyright.jsx" />
            <span className="ml-3">{' Entertainment Designed'}</span>
          </a>
        </div>
      </div>
    </div>;
};
export default Copyright;