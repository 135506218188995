'use client';

import Badge from '@/components/ui/Badge';
import { ROUTES } from '@/data';
import { Link } from '@/i18n/routing';
import { isPro, isStar } from '@/utils';
function NavProfileDropdown({
  user
}) {
  return <Link href={ROUTES.PROFILE} className="flex items-center py-2 rounded-md cursor-pointer nav-user hoverable" data-sentry-element="Link" data-sentry-component="NavProfileDropdown" data-sentry-source-file="NavProfileDropdown.jsx">
      <div className="flex flex-col items-start ml-2">
        <span className="text-sm font-medium leading-none truncate">
          {user?.spotifyUsername ?? user?.spotifyId}
        </span>

        {user?.contacts?.email && <span className="mt-1 text-xs text-muted-foreground">
            {user?.contacts?.email}
          </span>}

        <div className="flex flex-wrap items-center gap-1 mt-1">
          {isStar(user?.type) && <Badge size="sm" variant="gold-light" label="Star" />}

          {isPro(user?.type) && <Badge size="sm" variant="blue-light" label="Pro" />}

          {user?.plan?.name && <Badge size="sm" variant="primary-light" className="text-xs capitalize" label={user?.plan?.name} />}
        </div>
      </div>
    </Link>;
}
export default NavProfileDropdown;