import Emoji from '@/components/ui/Emoji';
import { CURRENCY_SYMBOL } from '@/data';
import { cn } from '@/utils';
export function Coins({
  credits = 0,
  gems = 0,
  className
}) {
  const classes = cn({
    'credit-score': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="Coins" data-sentry-source-file="Coins.jsx">
      <div className="inline-flex items-center gap-1">
        <span className="coin-symbol">{CURRENCY_SYMBOL}</span>
        {credits}
      </div>

      <span className="w-[1px] h-6 mx-1 bg-border" />

      <div className="inline-flex items-center gap-1">
        <span className="gem-symbol">
          <Emoji symbol="💎" label="Gem" className="text-lg" data-sentry-element="Emoji" data-sentry-source-file="Coins.jsx" />
        </span>
        {gems}
      </div>
    </div>;
}
export default Coins;