'use client';

import NavListItem from './NavListItem';
import NavLogo from './NavLogo';
import Icon from '@/components/ui/Icon';
import { NavigationMenu, NavigationMenuLink, NavigationMenuContent, NavigationMenuItem, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";
import { CAMPAIGN_TYPOLOGY_OPTIONS } from '@/components/VipCampaign/config';
import { NAV, MATCH_CHANNELS, ROUTES } from '@/data';
import { useTranslations, useActiveRoute } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
const NavMain = () => {
  const t = useTranslations();
  const {
    isActive
  } = useActiveRoute();
  return <div className="flex items-center nav-main" data-sentry-component="NavMain" data-sentry-source-file="NavMain.jsx">
      <NavLogo data-sentry-element="NavLogo" data-sentry-source-file="NavMain.jsx" />

      <NavigationMenu className="hidden lg:flex" data-sentry-element="NavigationMenu" data-sentry-source-file="NavMain.jsx">
        <NavigationMenuList className="h-full" data-sentry-element="NavigationMenuList" data-sentry-source-file="NavMain.jsx">
          <NavigationMenuItem key={ROUTES.MATCH} data-sentry-element="NavigationMenuItem" data-sentry-source-file="NavMain.jsx">
            <NavigationMenuTrigger className={cn({
            'nav-item': true,
            active: MATCH_CHANNELS.some(item => isActive(item.path))
          })} data-sentry-element="NavigationMenuTrigger" data-sentry-source-file="NavMain.jsx">
              <Icon name="Music" data-sentry-element="Icon" data-sentry-source-file="NavMain.jsx" />
              {t('navigation.match')}
            </NavigationMenuTrigger>

            <NavigationMenuContent data-sentry-element="NavigationMenuContent" data-sentry-source-file="NavMain.jsx">
              <ul className="grid w-[400px] gap-1 p-2 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                {MATCH_CHANNELS.map(item => <NavListItem {...item} key={item.path} href={item.path} title={t(item.title)} subtitle={t(item.subtitle)} />)}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          {NAV.map(item => <NavigationMenuItem key={item.path}>
              <NavigationMenuLink asChild className={cn(navigationMenuTriggerStyle(), isActive(item.path) && 'active')}>
                <Link href={item.path}>
                  {item?.icon && <Icon name={item.icon} />}
                  {t(item.title)}

                </Link>
              </NavigationMenuLink>
            </NavigationMenuItem>)}

          <NavigationMenuItem key={ROUTES.VIP_CAMPAIGNS} data-sentry-element="NavigationMenuItem" data-sentry-source-file="NavMain.jsx">
            <NavigationMenuTrigger className={cn({
            'nav-item': true,
            active: isActive(ROUTES.VIP_CAMPAIGNS)
          })} data-sentry-element="NavigationMenuTrigger" data-sentry-source-file="NavMain.jsx">
              <Icon name="Gem" data-sentry-element="Icon" data-sentry-source-file="NavMain.jsx" />
              {'VIP Promo'}
            </NavigationMenuTrigger>

            <NavigationMenuContent data-sentry-element="NavigationMenuContent" data-sentry-source-file="NavMain.jsx">
              <ul className="grid w-[400px] gap-1 p-2 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                {CAMPAIGN_TYPOLOGY_OPTIONS(t).filter(item => item.enabled).map(item => <NavListItem key={item.value} href={`${ROUTES.VIP}?type=${item.value}`} title={item.label} subtitle={item.subtitle} icon={item.icon} iconType={item.iconType} />)}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </div>;
};
export default NavMain;