'use client';

import * as React from "react";
import { Drawer as DrawerPrimitive } from "vaul";
import { cn } from "@/utils";
const Drawer = ({
  shouldScaleBackground = true,
  ...props
}) => <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} data-sentry-element="unknown" data-sentry-component="Drawer" data-sentry-source-file="drawer.jsx" />;
Drawer.displayName = "Drawer";
const DrawerTrigger = DrawerPrimitive.Trigger;
const DrawerPortal = DrawerPrimitive.Portal;
const DrawerClose = DrawerPrimitive.Close;
const DrawerOverlay = ({
  ref,
  className,
  ...props
}) => <DrawerPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-black/80", className)} {...props} data-sentry-element="unknown" data-sentry-component="DrawerOverlay" data-sentry-source-file="drawer.jsx" />;
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;
const DrawerContent = ({
  ref,
  className,
  children,
  ...props
}) => <DrawerPortal data-sentry-element="DrawerPortal" data-sentry-component="DrawerContent" data-sentry-source-file="drawer.jsx">
  <DrawerOverlay data-sentry-element="DrawerOverlay" data-sentry-source-file="drawer.jsx" />
  <DrawerPrimitive.Content ref={ref} className={cn("fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-popover", className)} {...props} data-sentry-element="unknown" data-sentry-source-file="drawer.jsx">
    <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
    {children}
  </DrawerPrimitive.Content>
</DrawerPortal>;
DrawerContent.displayName = "DrawerContent";
const DrawerHeader = ({
  className,
  ...props
}) => <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} data-sentry-component="DrawerHeader" data-sentry-source-file="drawer.jsx" />;
DrawerHeader.displayName = "DrawerHeader";
const DrawerFooter = ({
  className,
  ...props
}) => <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} data-sentry-component="DrawerFooter" data-sentry-source-file="drawer.jsx" />;
DrawerFooter.displayName = "DrawerFooter";
const DrawerTitle = ({
  ref,
  className,
  ...props
}) => <DrawerPrimitive.Title ref={ref} className={cn("text-lg font-semibold leading-none tracking-tight", className)} {...props} data-sentry-element="unknown" data-sentry-component="DrawerTitle" data-sentry-source-file="drawer.jsx" />;
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;
const DrawerDescription = ({
  ref,
  className,
  ...props
}) => <DrawerPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} data-sentry-element="unknown" data-sentry-component="DrawerDescription" data-sentry-source-file="drawer.jsx" />;
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
export { Drawer, DrawerPortal, DrawerOverlay, DrawerTrigger, DrawerClose, DrawerContent, DrawerHeader, DrawerFooter, DrawerTitle, DrawerDescription };