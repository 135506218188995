'use client';

import { useEffect, useState } from 'react';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { DEVELOPMENT, PLANS, PLAN_PRICES, PROFILE_COMPLETE_BONUS, ROUTES } from '@/data';
import { useSession, useDimensions, useTranslations } from '@/hooks';
import { cn, planRedirect, isFree } from '@/utils';
export function Banner({
  className
}) {
  const t = useTranslations();
  const [showBanner, setShowBanner] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const [hasBilling, setHasBilling] = useState(true);
  const [ref, {
    height
  }] = useDimensions({});
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  useEffect(() => {
    const hasDismissed = window.localStorage.getItem('banner-storage');
    if (hasDismissed) {
      setDismissed(true);
      return;
    }
  }, []);
  useEffect(() => {
    if (user && user?.spotifyId && !user?.billingInfo) {
      setHasBilling(false);
    }
  }, [user]);
  useEffect(() => {
    const body = document.querySelector('body');
    if (!user || !user?.plan || dismissed || !isFree(user) && hasBilling) {
      setShowBanner(false);
      body.classList.remove('has-banner');
      return;
    }
    setShowBanner(true);
    body.classList.add('has-banner');
  }, [user, dismissed, hasBilling]);
  useEffect(() => {
    if (height) {
      document.body.style.setProperty('--banner-height', `${height}px`);
    }
  }, [height]);
  const handleDismiss = () => {
    setDismissed(true);
    window.localStorage.setItem('banner-storage', true);
  };
  const upgradeDiscount = ((PLAN_PRICES[PLANS.ARTIST].price - PLAN_PRICES[PLANS.ARTIST].salePrice) / PLAN_PRICES[PLANS.ARTIST].price * 100).toFixed(0);
  const classes = cn({
    banner: true,
    'banner-fixed': true,
    'banner-bottom': true,
    'banner-free': isFree(user),
    'banner-billing': !isFree(user) && !hasBilling,
    [className]: className
  });
  if (DEVELOPMENT) return null;
  if (showBanner) {
    return <div className={classes} ref={ref}>
        {isFree(user) && <>
            <div className="banner-content">
              <p className="font-medium">
                {t('banner.free.text', {
              plan: 'Artist'
            })}
              </p>
              <Badge variant="green-light" className="font-bold">
                {t('banner.free.badge', {
              discount: upgradeDiscount
            })}
              </Badge>
            </div>

            <div className="flex items-center gap-2">
              <Button size="sm" href={planRedirect(PLANS.ARTIST)} variant="green">
                {t('banner.free.actions.upgrade')}
              </Button>
              <Button icon="X" variant="ghost" onClick={() => handleDismiss()} />
            </div>
          </>}

        {!isFree(user) && !hasBilling && <>
            <div className="banner-content">
              <p className="font-medium">
                {t('banner.billing.text', {
              credits: PROFILE_COMPLETE_BONUS
            })}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <Button size="sm" href={ROUTES.BILLING} variant="orange">
                {t('banner.billing.cta')}
              </Button>
              <Button icon="X" variant="ghost" onClick={() => handleDismiss()} />
            </div>
          </>}
      </div>;
  }
}
export default Banner;