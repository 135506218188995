import NavProfile from './NavProfile';
import { AuthButton } from '@/components/Auth';
import { ChatNavItem } from '@/components/Chat';
import SearchCommand from '@/components/Search/SearchCommand';
import { CreditsModal } from '@/components/ui/Credits';
import { NotificationDropdown } from '@/components/ui/Notification';
import { useSession } from '@/hooks';
function NavRight() {
  const {
    data: session
  } = useSession();
  return <div className="flex items-center gap-[.2rem]" data-sentry-component="NavRight" data-sentry-source-file="NavRight.jsx">
      <SearchCommand data-sentry-element="SearchCommand" data-sentry-source-file="NavRight.jsx" />

      {session ? <>
          <CreditsModal />
          <ChatNavItem className="px-2" />
          <NotificationDropdown triggerClasses="px-2" />
        </> : <AuthButton icon="LogIn" variant="primary" />}

      <NavProfile data-sentry-element="NavProfile" data-sentry-source-file="NavRight.jsx" />
    </div>;
}
export default NavRight;