import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import * as React from 'react';
import { cn } from '@/utils';
const ScrollArea = ({
  ref,
  className,
  children,
  ...props
}) => <ScrollAreaPrimitive.Root ref={ref} className={cn('relative overflow-hidden', className)} {...props} data-sentry-element="unknown" data-sentry-component="ScrollArea" data-sentry-source-file="ScrollArea.jsx">
  <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]" data-sentry-element="unknown" data-sentry-source-file="ScrollArea.jsx">
    {children}
  </ScrollAreaPrimitive.Viewport>
  <ScrollBar data-sentry-element="ScrollBar" data-sentry-source-file="ScrollArea.jsx" />
  <ScrollAreaPrimitive.Corner data-sentry-element="unknown" data-sentry-source-file="ScrollArea.jsx" />
</ScrollAreaPrimitive.Root>;
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;
const ScrollBar = ({
  ref,
  className,
  orientation = 'vertical',
  ...props
}) => <ScrollAreaPrimitive.ScrollAreaScrollbar ref={ref} orientation={orientation} className={cn('flex touch-none select-none transition-colors', orientation === 'vertical' && 'h-full w-2.5 border-l border-l-transparent p-[1px]', orientation === 'horizontal' && 'h-2.5 border-t border-t-transparent p-[1px]', className)} {...props} data-sentry-element="unknown" data-sentry-component="ScrollBar" data-sentry-source-file="ScrollArea.jsx">
  <ScrollAreaPrimitive.ScrollAreaThumb className={cn('relative rounded-full bg-border', orientation === 'vertical' && 'flex-1')} data-sentry-element="unknown" data-sentry-source-file="ScrollArea.jsx" />
</ScrollAreaPrimitive.ScrollAreaScrollbar>;
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;
export { ScrollArea, ScrollBar };
export default ScrollArea;