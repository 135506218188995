import Icon from '@/components/ui/Icon';
import { TrustpilotMiniWidget } from '@/components/ui/Trustpilot';
import { ROUTES } from '@/data';
const Social = () => {
  const SOCIAL_LINKS = [{
    name: 'Facebook',
    href: ROUTES.FACEBOOK,
    icon: 'Facebook'
  }, {
    name: 'Instagram',
    href: ROUTES.INSTAGRAM,
    icon: 'Instagram'
  }, {
    name: 'TikTok',
    href: ROUTES.TIKTOK_PROFILE,
    icon: 'TikTok',
    className: 'h-7 w-7'
  }];
  return <div className="flex flex-col items-start justify-between col-span-2 md:col-span-1" data-sentry-component="Social" data-sentry-source-file="Social.jsx">
      <ul className="footer-socials">
        {SOCIAL_LINKS.map(link => <li key={link.name}>
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              <Icon size="lg" name={link.icon} type="brand" className={link.className} />
            </a>
          </li>)}
      </ul>

      <TrustpilotMiniWidget className="mt-6 md:mt-0 ml-[-1.5rem]" data-sentry-element="TrustpilotMiniWidget" data-sentry-source-file="Social.jsx" />
    </div>;
};
export default Social;